'use client'

import GlobalContext from '@/components/globalContext'
import { TemplateUserConfigResp, UIUser } from '@/lib/types'

export function Providers({
  children,
  value,
}: {
  children: React.ReactNode
  value: {
    user: UIUser
    config: TemplateUserConfigResp
    isSubscribed: boolean
  }
}) {
  return (
    <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
  )
}
