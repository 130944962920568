'use client'
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@/components/ui/alert-dialog'
import { Button } from '@/components/ui/button'
import { useIsSubscribed } from '@/lib/hooks/context'

export function SubscribeBanner() {
  const isSubscribed = useIsSubscribed()

  if (isSubscribed) {
    return null
  }

  return (
    <AlertDialog open={true}>
      <AlertDialogTrigger asChild>
        <Button variant="outline">Unlock Full Access</Button>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>
            Enjoy noto.so? Upgrade to continue
          </AlertDialogTitle>
          <AlertDialogDescription>
            {`Upgrade to a premium subscription to seamlessly convert your Notion pages to beautiful blogs. As the site owner, you'll enjoy uninterrupted access to all our advanced features and take your blog experience to the next level!`}
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogAction>
            <a href="https://noto.so/#pricing">Upgrade</a>
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}
